import { useEffect, useState } from "react";
import { useHoraires } from "../../horaires/hooks/useHoraires.ts";
import useApptsByDateWindow from "./useApptsByDateWindow.ts";
import { usePreferences } from "../../preferences/hooks/usePreferences.ts";
import { Transforms } from "cadendar-shared";

interface NewAptState {
  date: Date;
  heure: number;
  minute: number;
  duree: number;
  type: number;
  objet: string;
  reminderChecked: boolean;
  confirmChecked: boolean;
}

interface NewAptProposedCaracs {
  heure: number;
  minute: number;
  date: Date;
}

function getInitialState(rdv: NewAptProposedCaracs, dureeMinRdv: number) {
  return {
    ...rdv,
    reminderChecked: false as boolean,
    confirmChecked: false as boolean,
    type: 1,
    objet: "",
    duree: dureeMinRdv,
  } satisfies NewAptState;
}

const useNewApt = (rdvState: NewAptProposedCaracs, date: Date) => {
  const prefs = usePreferences();
  const minDuree = prefs?.duréeMinRdv || 15;
  const [tempRdv, setTempRdv] = useState(getInitialState(rdvState, minDuree));

  useEffect(() => {
    if (!rdvState) return;
    setTempRdv(getInitialState(rdvState, minDuree));
  }, [JSON.stringify(rdvState)]);
  const horaire = useHoraires(tempRdv && tempRdv.date);
  const allRdvs = useApptsByDateWindow(date, date);

  const onSetHM = ({ heure, minute }: { heure: number; minute: number }) => {
    setTempRdv((tempRdv) => {
      const dureesPossibles =
        horaire &&
        prefs &&
        tempRdv &&
        Transforms.rdvs.getDurees2(
          horaire,
          allRdvs,
          tempRdv.date,
          { heure, minute },
          prefs.duréeMinRdv
        );
      const choosenDuree =
        (dureesPossibles &&
          (dureesPossibles.includes(tempRdv.duree)
            ? tempRdv.duree
            : dureesPossibles[0])) ||
        0;
      return { ...tempRdv, heure, minute, duree: choosenDuree };
    });
  };

  const onSetDuree = (duree: number) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, duree }));
  };
  const onSetType = (type: number) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, type }));
  };
  const onSetObjet = (objet: string) => {
    setTempRdv((tempRdv) => ({ ...tempRdv, objet }));
  };
  const onToggleReminder = () => {
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      reminderChecked: !tempRdv.reminderChecked,
    }));
  };
  const onToggleConfirm = () => {
    setTempRdv((tempRdv) => ({
      ...tempRdv,
      confirmChecked: !tempRdv.confirmChecked,
    }));
  };
  const hmPossibles =
    (horaire &&
      prefs &&
      rdvState &&
      Transforms.rdvs.hmAvailableWithDuration(
        horaire,
        allRdvs,
        rdvState.date,
        prefs.duréeMinRdv,
        tempRdv.duree
      )) ||
    [];
  const dureesPossibles =
    (tempRdv &&
      prefs &&
      horaire &&
      Transforms.rdvs.getDurees2(
        horaire,
        allRdvs,
        date,
        { heure: tempRdv.heure, minute: tempRdv.minute },
        prefs.duréeMinRdv
      )) ||
    [];
  return {
    tempRdv,
    hmPossibles,
    dureesPossibles,
    onSetHM,
    onSetDuree,
    onSetType,
    onSetObjet,
    onToggleReminder,
    onToggleConfirm,
  };
};

export { useNewApt };
