import { trpc } from "../../../main/trpc.ts";

function useApptsByDateWindow(startDate: Date, endDate: Date) {
  const { data: appts } = trpc.rdv.getByDateWindow.useQuery({
    startDate,
    endDate,
  });
  return appts || [];
}

export default useApptsByDateWindow;
