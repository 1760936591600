import { css, cva } from "../../../styled-system/css";

interface AlertProps {
  message: string;
  type: "success" | "error";
  show: boolean;
  autoClose: boolean;
  onClose: () => void;
}

const AlertStyleCVA = cva({
  base: {
    zIndex: 1000,
    backgroundColor: "green.200",
    width: "80%",
    padding: 5,
    textAlign: "center",
    borderRadius: 5,
  },
  variants: {
    type: {
      success: {
        backgroundColor: "green.200",
        borderColor: "green.300",
        color: "green.800",
      },
      error: {
        backgroundColor: "red.200",
        borderColor: "red.300",
        color: "red.800",
      },
    },
  },
});

const Alert = (props: AlertProps) => {
  return (
    <div>
      {props.show && (
        <div
          className={css({
            zIndex: 1000,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            top: 2,
            left: 0,
            position: "fixed",
          })}
        >
          <div className={AlertStyleCVA({ type: props.type })}>
            <div>{props.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Alert;
