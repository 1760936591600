import { styled } from "../../styled-system/jsx";
const Select = styled("select", {
  base: {
    backgroundColor: "white",
    borderRadius: 5,
    paddingX: 2,
    paddingY: 1.5,
    borderWidth: 2,
    borderColor: "gray.200",
    cursor: "pointer",
    _active: {
      opacity: 0.5,
    },
    _hover: {
      opacity: 0.5,
    },
    _focus: {
      outline: "none",
    },
  },
});

export default Select;
