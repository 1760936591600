import poster from "../utils/poster";

const prefix = "cadendarRest";
async function getToken(emailOrUsername: string, password: string) {
  return await poster<{ token: string }>(`${prefix}/getToken`, {
    username: emailOrUsername,
    password,
  });
}

import { User } from "cadendar-shared";

const devHost = import.meta.env.VITE_DEV_HOST || "";
async function getConnectedUser(token: string): Promise<User> {
  const response = await fetch(
    `${devHost ? devHost + "/" : ""}${prefix}/getConnectedUser`,
    {
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    }
  );
  if (response.ok) {
    const json = await response.text();
    return JSON.parse(json);
  } else {
    throw new Error(`invalide json dans getConnectedUser`);
  }
}

export { getToken, getConnectedUser };
