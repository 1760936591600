import style from "./EtatCivilField.module.css";
import classNames from "classnames";
interface EtatCivilFieldProps {
  isValid: boolean;
  errors: string | undefined;
  touched: boolean;
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  id: string; //pour formik
  name: string; //pour formik
  isEditing: boolean;
}

const EtatCivilField = (props: EtatCivilFieldProps) => {
  const { isValid, label, value, onChange, onBlur, isEditing } = props;
  return (
    <>
      <label
        className={style.formLabel}
        htmlFor={"new_patient_" + label + "_input_field"}
      >
        {" "}
        {label} :
      </label>
      <input
        type="text"
        className={classNames({
          [style.input]: true,
          [style.edit]: isEditing,
          [style.inputFailure]: value && !isValid,
        })}
        id={"new_patient_" + label.split(" ").join("_") + "_input_field"}
        placeholder={label}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        onBlur={onBlur}
        readOnly={!isEditing}
        disabled={!isEditing}
      />
    </>
  );
};

export default EtatCivilField;
