import {
  ClosedByHoraireRdv,
  ClosedRdvCase,
  EmptyRdvCase,
  FilledRdvCase,
} from "cadendar-shared";
import _ from "underts";
import { formaters } from "vincent-utils";
import { useRef } from "react";
import { CadendarConsts } from "cadendar-shared";
import XMark from "../assets/xmark-svgrepo-com.svg?react";
import styles from "./AgendaCase.module.css";

export type RdvCaseType =
  | ClosedByHoraireRdv
  | ClosedRdvCase
  | EmptyRdvCase
  | FilledRdvCase;

interface AgendaCaseProps {
  appt: RdvCaseType;
  onPress: (appt: RdvCaseType) => void;
  onCrossClick: (appt: FilledRdvCase) => void;
  isAccentuated: boolean;
}

// const agendaCaseCva = cva({
//   base: {
//     borderWidth: 2,
//     borderRadius: 5,
//     borderColor: "#e1ebff",
//     paddingLeft: 2,
//     _active: { opacity: 0.5 },
//     cursor: "pointer",
//   },
//   variants: {
//     colorPalette: {
//       red: {
//         backgroundColor: "#f96c6c", //base FA1E20
//         color: "black",
//       },
//       green: {
//         backgroundColor: "#6afb93", //base 2AFA64
//         color: "black",
//       },
//       blue: {
//         backgroundColor: "#82c3fc", //base 128DFA,
//         color: "black",
//       },
//       purple: {
//         backgroundColor: "#e2b4fd", //base #AD2AFA
//         color: "black",
//       },
//       yellow: {
//         backgroundColor: "#F7FC47",
//         color: "black",
//       },
//       darkGreen: {
//         backgroundColor: "#1A963D",
//         color: "black",
//       },
//       pink: {
//         backgroundColor: "#FA1E92",
//         color: "black",
//       },
//       darkNavyBlue: {
//         backgroundColor: "#2d187a",
//         color: "white",
//       },
//       white: {
//         backgroundColor: "white",
//         color: "black",
//         _active: { backgroundColor: "#e1ebff" },
//       },
//       dark: {
//         backgroundColor: "#5c5c5c",
//         color: "white",
//         fontWeight: "bold",
//       },
//       brownYellow: {
//         backgroundColor: "#f1f1d9",
//         color: "black",
//       },
//     },
//     accent: {
//       none: {},
//       edit: {
//         borderColor: "red",
//         borderWidth: 3,
//       },
//     },
//   },
// });

const colorTypesMap = {
  1: "green",
  2: "yellow",
  5: "blue",
  10: "darkGreen",
  11: "red",
  12: "red",
  13: "purple",
  14: "pink",
  15: "dark",
  18: "purple",
  21: "red",
  22: "red",
  26: "green",
  28: "darkNavyBlue",
} as const;

const getRdvColor = (rdv: RdvCaseType) => {
  if (rdv.tag === "closed") {
    return "dark";
  }
  if (rdv.tag === "empty") {
    return "white";
  }
  if (rdv.tag === "closedByHoraire") {
    return "dark";
  }
  const color = _.getSafeMapValue(colorTypesMap, rdv.type);
  if (!color) {
    return "brownYellow";
  }
  return color;
};

function getHeader(rdv: RdvCaseType) {
  if (rdv.tag === "rdv") {
    if (!rdv.nom && !rdv.prenom) {
      return undefined;
    }
    return (
      ((rdv.nom && rdv.nom.toLocaleUpperCase("fr")) || "") +
      " " +
      ((rdv.prenom && formaters.capitaliseEveryFirstLetter(rdv.prenom)) || "")
    );
  }
  if (rdv.tag === "closedByHoraire") {
    return "Fermé (horaires)";
  }
  if (rdv.tag === "closed") {
    return "Fermé";
  }
  if (rdv.tag === "empty") {
    return undefined;
  }
}
function getSubHeader(rdv: RdvCaseType) {
  if (rdv.tag === "rdv") {
    return rdv.objet;
  }
  if (rdv.tag === "closedByHoraire") {
    return "";
  }
  if (rdv.tag === "closed") {
    return "";
  }
  if (rdv.tag === "empty") {
    return "";
  }
}

// const agendaTextStyleCva = cva({
//   variants: {
//     style: {
//       normal: {},
//       canceled: {
//         textDecoration: "line-through",
//         textDecorationThickness: 2,
//       },
//     },
//   },
// });

const isCrossShown = (rdv: RdvCaseType) => {
  return (
    rdv.tag === "rdv" &&
    (rdv.type === CadendarConsts.typeConsts.ANN ||
      rdv.type === CadendarConsts.typeConsts.ANNTARD)
  );
};

const AgendaCase = (props: AgendaCaseProps) => {
  const ref = useRef(null);
  const appt = props.appt;
  const colorStyle = getRdvColor(appt);
  const header = getHeader(appt);

  // const bindLongPress = useLongPress(() => {
  //   props.onPress(appt);
  // });
  return (
    <div
      ref={ref}
      className={`${styles.agendaCase} ${styles[colorStyle]} ${
        props.isAccentuated ? styles.accentEdit : ""
      }`}
      style={{ height: (appt.duree / 5) * 17 }}
      key={
        appt.tag === "rdv"
          ? appt._id
          : appt.date.toISOString() + appt.heure + appt.minute
      }
      onClick={() => {
        console.log("click");
        props.onPress(appt);
      }}
      onTouchEnd={() => {
        console.log("touchend");
      }}
    >
      <div className={styles.caseExtLayout}>
        <div
          className={
            "type" in appt &&
            (appt.type === CadendarConsts.typeConsts.ANN ||
              appt.type === CadendarConsts.typeConsts.ANNTARD)
              ? styles.canceled
              : ""
          }
        >
          {header ? (
            <div className={styles.header}>{getHeader(appt)}</div>
          ) : null}
          <div>{getSubHeader(appt)}</div>
        </div>
        {isCrossShown(appt) ? (
          <div className={styles.horizontalPaddingOne}>
            <XMark
              className={styles.iconStyle}
              onClick={(e) => {
                e.stopPropagation();
                props.onCrossClick(appt as FilledRdvCase);
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AgendaCase;
