import { cva } from "../../../styled-system/css";

export const formLabelTextStyle = cva({
  base: {
    fontFamily: "Open Sans",
    fontSize: 16,
    fontWeight: 600,
    cursor: "pointer",
  },
  variants: {
    disabled: {
      true: {
        color: "gray.400",
        cursor: "not-allowed",
      },
    },
  },
});
