import ChevronLeft from "../assets/chevron-left.svg?react";
import ChevronRight from "../assets/chevron-right.svg?react";
import ChevronDoubleLeft from "../assets/chevron-double-left.svg?react";
import ChevronDoubleRight from "../assets/chevron-double-right.svg?react";
import { formaters } from "vincent-utils";
import { DateTime } from "luxon";
import styles from "./HeaderBar.module.css";

interface HeaderBarProps {
  dateSelected: Date;
  setDateSelected: (date: Date) => void;
  onDateClick: () => void;
  isToday: boolean;
}

const HeaderBar = (props: HeaderBarProps) => {
  const handleNextDay = () => {
    props.setDateSelected(
      DateTime.fromJSDate(props.dateSelected).plus({ day: 1 }).toJSDate()
    );
  };
  const handlePreviousDay = () => {
    props.setDateSelected(
      DateTime.fromJSDate(props.dateSelected).minus({ day: 1 }).toJSDate()
    );
  };
  const handleNextWeek = () => {
    props.setDateSelected(
      DateTime.fromJSDate(props.dateSelected).plus({ day: 7 }).toJSDate()
    );
  };
  const handlePreviousWeek = () => {
    props.setDateSelected(
      DateTime.fromJSDate(props.dateSelected).minus({ day: 7 }).toJSDate()
    );
  };
  const dateSelectedString = formaters.capitaliseEveryFirstLetter(
    DateTime.fromJSDate(props.dateSelected)
      .setLocale("fr")
      .toFormat("ccc dd LLL yyyy")
  );
  return (
    <div className={styles.barStyle}>
      <ChevronDoubleLeft
        className={styles.button}
        onClick={handlePreviousWeek}
      />
      <ChevronLeft onClick={handlePreviousDay} className={styles.button} />
      <div
        onClick={props.onDateClick}
        className={`${styles.date}  ${props.isToday ? styles.today : ""}`}
      >
        {dateSelectedString}
      </div>
      <ChevronRight onClick={handleNextDay} className={styles.button} />
      <ChevronDoubleRight className={styles.button} onClick={handleNextWeek} />
    </div>
  );
};

export default HeaderBar;
