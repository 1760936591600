import styles from "./OkButton.module.css";
import Save from "../../assets/save-svgrepo-com.svg?react";

interface OkButtonProps {
  onClick: () => void;
  label: string;
}

const OkButton = (props: OkButtonProps) => {
  return (
    <button
      className={`${styles.button} ${styles.colorProceed}`}
      onClick={props.onClick}
    >
      <Save className={styles.buttonIcon} />
      {props.label}
    </button>
  );
};

export default OkButton;
