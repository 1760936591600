import { formaters } from "vincent-utils";
import _ from "underts";
import styles from "./GrilleHeure.module.css";

interface GrilleHeureProps {
  start: number;
  end: number;
  step: number;
  aptMinDuration: number;
  dayIdx: number;
}

const GrilleHeure = (props: GrilleHeureProps) => {
  const { start, end, step } = props;
  const { aptMinDuration } = props;
  const hmObjs = _.compact(
    _.range(start, end, step).map(
      formaters.getHeureMinuteObjectFromHeureEnMinutes
    )
  );
  const strings = hmObjs.map(formaters.formatHMObjectForDisplay);
  const isZeroMinute = (hmString: string) => hmString.split(":")[1] === "00";
  const divs = strings.map((str) => (
    <div
      key={props.dayIdx + str}
      className={`${styles.grilleCase} ${
        isZeroMinute(str)
          ? styles.grilleCaseTopStyleThick
          : styles.grilleCaseTopStyleThin
      }`}
      style={{
        height: (aptMinDuration / 5) * 17,
      }}
    >
      <div>{str}</div>
    </div>
  ));

  return <div data-testid="GrilleHeure">{divs}</div>;
};

export default GrilleHeure;
