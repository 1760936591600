import { useState } from "react";

type ShowAlertType =
  | {
      show: false;
    }
  | {
      show: true;
      message: string;
      type: "success" | "error";
    };

export default function useAlert() {
  const [showAlert, setShowAlert] = useState<ShowAlertType>({ show: false });
  const hideAlert = () => {
    setShowAlert({ show: false });
  };
  const showErrorAlert = (message: string) => {
    setShowAlert({ show: true, message, type: "error" });
    setTimeout(() => {
      hideAlert();
    }, 3000);
  };
  const showSuccessAlert = (message: string) => {
    setShowAlert({ show: true, message, type: "success" });
    setTimeout(() => {
      hideAlert();
    }, 3000);
  };
  const isAlertShown = showAlert.show;
  const alertType = showAlert.show ? showAlert.type : "error";
  const alertMessage = showAlert.show ? showAlert.message : null;

  return {
    isAlertShown,
    alertType,
    alertMessage,
    hideAlert,
    showErrorAlert,
    showSuccessAlert,
  };
}
