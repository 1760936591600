import { trpc } from "../../../main/trpc.ts";

function useHoraires(date: Date | null) {
  const { data: horaires } = trpc.horaire.byDate.useQuery(
    {
      date: new Date(date as Date),
    },
    { enabled: !!date && "getTime" in date }
  );
  return horaires || null;
}

export { useHoraires };
