import { css } from "../../styled-system/css";
import { useState } from "react";

const containerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100dvh",
};

const titleStyle = {
  fontSize: 30,
  color: "primary",
  fontWeight: "bold",
  textAlign: "center",
  margin: 0,
  paddingY: 10,
  fontFamily: "Roboto",
};

const inputStyle = {
  fontSize: 14,
  color: "gray.900",
  backgroundColor: "gray.50",

  border: 2,
  borderStyle: "solid",
  borderRadius: 5,
  borderColor: "gray.100",
  _focus: {
    borderColor: "gray.600",
    outline: "none",
  },

  // _focusVisible: {
  //     borderStyle: "hidden"
  // },
  padding: 2,
  width: 300,
  marginY: 2,
};

const submitButtonStyle = {
  fontSize: 14,
  color: "white",
  backgroundColor: "blue.800",
  borderRadius: 5,
  padding: 2,
  width: 300,
  marginY: 4,
  _active: {
    opacity: 0.5,
  },
  _disabled: {
    opacity: 0.5,
  },
  cursor: "pointer",
};

interface LoginScreenProps {
  onSubmit: (loginPass: { emailOrUsername: string; password: string }) => void;
  submitDisabled: boolean;
}

const LoginScreenUI = (props: LoginScreenProps) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const submitEnabled =
    (login.length > 4 && password.length > 4) || props.submitDisabled;
  const handleSubmit = () => {
    props.onSubmit({ emailOrUsername: login, password });
  };
  return (
    <div className={css(containerStyle)}>
      <div
        className={css({
          display: "flex",
          height: "100%",
          width: "80%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <div className={css(titleStyle)}>Cadendar Login</div>
        <input
          className={css(inputStyle, { margin: 3 })}
          placeholder="Email/Login"
          value={login}
          onChange={(e) => {
            setLogin(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" && submitEnabled) {
              handleSubmit();
            }
          }}
        />
        <input
          className={css(inputStyle, { margin: 3 })}
          placeholder="Mot de passe"
          type={"password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" && submitEnabled) {
              handleSubmit();
            }
          }}
        />
        <button
          className={css(submitButtonStyle)}
          disabled={!submitEnabled}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <div
          id="spacer"
          className={css({ marginTop: 20, height: "10dvh" })}
        ></div>
      </div>
    </div>
  );
};

export default LoginScreenUI;
