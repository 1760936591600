import HeaderBar from "./HeaderBar.tsx";
import { useState } from "react";
import { formaters } from "vincent-utils";
import { Rdv } from "cadendar-shared";
import AptEditScreen from "../modules/apts/AptEditScreen.tsx";
import AgendaColumnsSwiper from "./AgendaColumnsSwiper.tsx";
import { trpc } from "../main/trpc.ts";
import { DateTime } from "luxon";
import useAlert from "../baseComps/alert/useAlert.ts";
import Alert from "../baseComps/alert/Alert.tsx";
import NewAptFirstScreen from "../modules/apts/NewAptFirstScreen.tsx";
import style from "./AgendaMainScreen.module.css";
import PatientEdit from "../modules/patients/PatientEdit.tsx";

const AgendaMainScreen = () => {
  const [dateSelected, setDateSelected] = useState(
    formaters.filterDateTimeForPureDate(new Date())
  );
  const [aptSelected, setAptSelected] = useState<Rdv | null>(null); // [apt, setApt
  const [patientIdSelected, setPatientIdSelected] = useState<string | null>(
    null
  );
  const [isAptEditOpen, setIsAptEditOpen] = useState(false);
  const [isPatientEditOpen, setIsPatientEditOpen] = useState(false);
  const [emptyCaseSelected, setEmptyCaseSelected] = useState<null | {
    heure: number;
    minute: number;
    date: Date;
  }>(null);
  const {
    showErrorAlert,
    showSuccessAlert,
    hideAlert,
    isAlertShown,
    alertMessage,
    alertType,
  } = useAlert();
  const handleSaveSuccess = () => {
    showSuccessAlert("Modifications enregistrées");
    setEmptyCaseSelected(null);
    setIsAptEditOpen(false);
    setAptSelected(null);
  };
  const handleSaveError = () => {
    showErrorAlert("Erreur lors de l'enregistrement des modifications");
  };

  const setPreviousDay = () => {
    console.log("setPreviousDay");
    setDateSelected((dateSelected) =>
      DateTime.fromJSDate(dateSelected).minus({ day: 1 }).toJSDate()
    );
  };
  const setNextDay = () => {
    console.log("setNextDay");
    setDateSelected((dateSelected) =>
      DateTime.fromJSDate(dateSelected).plus({ day: 1 }).toJSDate()
    );
  };
  const startDate = DateTime.fromJSDate(
    DateTime.fromJSDate(dateSelected).startOf("week").toJSDate()
  )
    .minus({ day: 8 })
    .toJSDate();
  const endDate = DateTime.fromJSDate(startDate).plus({ day: 21 }).toJSDate();

  const previousSunday = DateTime.fromJSDate(
    DateTime.fromJSDate(dateSelected).startOf("week").toJSDate()
  )
    .minus({ day: 1 })
    .toJSDate();
  const { data: threeWeeksApts } = trpc.rdv.getByDateWindow.useQuery(
    {
      startDate: startDate,
      endDate: endDate,
    },
    { placeholderData: (val) => val }
  );
  const { data: horaires } = trpc.horaire.byDate.useQuery({
    date: previousSunday,
  });

  const trpcUtils = trpc.useContext();
  const archiveAptMutation = trpc.rdv.archive.useMutation({
    onSuccess: () => {
      trpcUtils.rdv.invalidate();
    },
  });

  const isToday = DateTime.fromJSDate(dateSelected).hasSame(
    DateTime.now(),
    "day"
  );

  const handleSelectApt = (apt: Rdv) => {
    setAptSelected(apt);
    setEmptyCaseSelected(null);
    setIsAptEditOpen(true);
  };
  const handleAptEditCancel = () => {
    setIsAptEditOpen(false);
    setAptSelected(null);
  };
  const handleNewAptCancel = () => {
    setEmptyCaseSelected(null);
    setIsAptEditOpen(false);
  };
  const handleEmptyCaseSelected = (emptyCase: {
    heure: number;
    minute: number;
    date: Date;
  }) => {
    setAptSelected(null);
    setIsAptEditOpen(false);
    setEmptyCaseSelected(emptyCase);
  };
  const handleArchiveApt = (apt: Rdv) => {
    console.log("archiveAptMutation.mutate", apt);
    archiveAptMutation.mutate({ _id: apt._id });
  };
  const handleSetDateSelected = (date: Date) => {
    setDateSelected((oldDate) => {
      if (oldDate.getTime() === date.getTime()) return oldDate;
      return date;
    });
  };
  const handleSetToday = () => {
    setDateSelected(formaters.filterDateTimeForPureDate(new Date()));
  };
  const handleSetPatientIdSelected = (patientId: string) => {
    setPatientIdSelected(patientId);
    setIsPatientEditOpen(true);
  };

  const dayRdvs =
    threeWeeksApts?.filter((apt) => {
      return (
        apt.date.getDate() === dateSelected.getDate() &&
        apt.date.getMonth() === dateSelected.getMonth() &&
        apt.date.getFullYear() === dateSelected.getFullYear()
      );
    }) || [];

  return (
    <div>
      <div className={style.mainScreenTopDiv}>
        <Alert
          message={alertMessage || ""}
          type={alertType}
          show={isAlertShown}
          autoClose={true}
          onClose={hideAlert}
        />
        <HeaderBar
          dateSelected={dateSelected}
          setDateSelected={handleSetDateSelected}
          onDateClick={handleSetToday}
          isToday={isToday}
        />
        <div
          className={
            isAptEditOpen ? style.agendaColumnReduced : style.agendaColumnFull
          }
        >
          {horaires ? (
            <AgendaColumnsSwiper
              dateSelected={dateSelected}
              apts={threeWeeksApts || []}
              hmSelected={aptSelected}
              horaires={horaires}
              onSetNextDay={setNextDay}
              onSetPreviousDay={setPreviousDay}
              onSelectApt={handleSelectApt}
              onClickEmptyCase={handleEmptyCaseSelected}
              onArchiveApt={handleArchiveApt}
            />
          ) : null}
        </div>
        {isPatientEditOpen && (
          <PatientEdit
            selectedPatientId={patientIdSelected}
            onSetSelectedPatientId={setPatientIdSelected}
            onClose={() => setIsPatientEditOpen(false)}
          />
        )}
        <div className={style.bottomDrawer}>
          {isAptEditOpen && aptSelected && (
            <AptEditScreen
              apt={aptSelected}
              date={dateSelected}
              onCancel={handleAptEditCancel}
              key={JSON.stringify(aptSelected)}
              onSaveSuccess={handleSaveSuccess}
              onSaveError={handleSaveError}
              onPatientEditClick={handleSetPatientIdSelected}
            />
          )}
          {emptyCaseSelected && (
            <NewAptFirstScreen
              dateSelected={dateSelected}
              onSuccess={handleSaveSuccess}
              onError={handleSaveError}
              onClose={handleNewAptCancel}
              dayRdvs={dayRdvs}
              heure={emptyCaseSelected.heure}
              minute={emptyCaseSelected.minute}
              onPatientEditClick={handleSetPatientIdSelected}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AgendaMainScreen;
