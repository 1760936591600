import Select from "../../baseComps/Select.tsx";
import { formaters } from "vincent-utils";
import { DateTime } from "luxon";
import RecycleBin from "../../assets/recycle-bin.svg?react";
import { CadendarConsts } from "cadendar-shared";
import styles from "./AptCaracsEdit.module.css";
import PencilEdit01Icon from "../icons/pencil-edit-01-stroke-rounded.tsx";
import classNames from "classnames";
import OkButton from "../buttons/OkButton.tsx";

interface AptEditHeaderProps {
  nom: string;
  prenom: string;
  date: Date;
  onCancelEdit: () => void;
  onPatientEditClick: () => void;
}
const AptEditHeader = (props: AptEditHeaderProps) => {
  return (
    <div className={styles.topBar}>
      <div>
        <div className={styles.spacer}>
          <div className={styles.patientName}>
            {props.nom?.toLocaleUpperCase("fr") +
              " " +
              (props.prenom && props.prenom
                ? formaters.capitaliseEveryFirstLetter(props.prenom)
                : "")}
            <PencilEdit01Icon
              color="white"
              style={{ paddingTop: 3, cursor: "pointer" }}
              onClick={props.onPatientEditClick}
            />
          </div>

          <span className={styles.circle} onClick={props.onCancelEdit}>
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.icon}
            >
              <path
                className={styles.icon}
                d="M6 6L18 18M18 6L6 18"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div>
        <div>
          <div className={styles.date}>
            {formaters.capitaliseEveryFirstLetter(
              DateTime.fromJSDate(props.date)
                .setLocale("fr")
                .toFormat("cccc dd LLL y")
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface AptCaracsEditProps {
  apt: {
    date: Date;
    nom: string;
    prenom: string;
    duree: number;
    heure: number;
    minute: number;
    type: number;
    objet: string;
    reminderChecked: boolean;
    confirmChecked: boolean;
  };
  cancelEnabled: boolean;
  hmPossibles: { heure: number; minute: number }[];
  dureesPossibles: number[];
  remindersCheckboxEnabled: boolean;
  confirmCheckboxEnabled: boolean;
  onSetDuree: (duree: number) => void;
  onSetType: (type: number) => void;
  onSetObjet: (objet: string) => void;
  onToggleReminder: () => void;
  onToggleConfirm: () => void;
  onSetHM: (hm: { heure: number; minute: number }) => void;
  onCancel: () => void;
  onCancelEdit: () => void;
  onSave: () => void;
  onEditPatient: () => void;
}

const AptCaracsEdit = (props: AptCaracsEditProps) => {
  const { apt } = props;
  const types = CadendarConsts.typeNums.map(
    CadendarConsts.getTypeNameFromTypeNumber
  );
  types.sort();
  return (
    <div className={styles.container}>
      <AptEditHeader
        date={apt.date}
        nom={apt.nom || ""}
        prenom={apt.prenom || ""}
        onCancelEdit={props.onCancelEdit}
        onPatientEditClick={props.onEditPatient}
      />
      <div className={styles.body}>
        <div className={styles.bodySpacer}>
          <div>
            <div className={styles.formLabel}>Heure</div>
            <Select
              value={
                formaters.formatHMObjectForDisplay({
                  heure: apt.heure,
                  minute: apt.minute,
                }) || "0:00"
              }
              onChange={(event) => {
                const splitted = event.target.value.split(":");
                const heure = parseInt(splitted[0]);
                const minute = parseInt(splitted[1]);
                props.onSetHM({ heure, minute });
              }}
            >
              {props.hmPossibles.map((hm) => (
                <option
                  value={formaters.formatHMObjectForDisplay(hm)}
                  key={JSON.stringify(hm)}
                >
                  {formaters.formatHMObjectForDisplay(hm)}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <div className={styles.formLabel}>Durée</div>
            <Select
              value={apt.duree || 0}
              onChange={(event) => {
                props.onSetDuree(parseInt(event.target.value));
              }}
            >
              {props.dureesPossibles.map((duree) => (
                <option value={duree} key={duree}>
                  {duree}
                </option>
              ))}
            </Select>
          </div>
          <div className="">
            <div className={styles.formLabel}>Type</div>
            <Select
              value={
                CadendarConsts.getTypeNameFromTypeNumber(apt.type) ||
                CadendarConsts.getTypeNameFromTypeNumber(0)
              }
              onChange={(event) => {
                console.log("event.target.value", event.target.value);
                const parsed = CadendarConsts.getTypeNumberFromTypeName(
                  event.target.value as CadendarConsts.TypesNames
                );
                if (!parsed) {
                  console.error("parsed", parsed);
                  throw new Error("parsed is undefined");
                }
                props.onSetType(parsed);
              }}
            >
              {types.map((type) => (
                <option value={type} key={"type" + type}>
                  {type}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div>
          <label htmlFor="objetInput" className={styles.formLabel}>
            Objet
          </label>
          <input
            type="text"
            value={apt.objet}
            id={"objetInput"}
            className={styles.input}
            onChange={(event) => {
              props.onSetObjet(event.target.value);
            }}
          />
        </div>
        <div className={styles.checkBoxBar}>
          <div className={styles.flex}>
            <input
              type="checkbox"
              checked={apt.reminderChecked}
              onChange={props.onToggleReminder}
              className={styles.checkBox}
              disabled={!props.remindersCheckboxEnabled}
            />

            <div
              onClick={props.onToggleReminder}
              className={classNames(
                {
                  [styles.formLabel]: true,
                  [styles.disabled]: !props.remindersCheckboxEnabled,
                },
                styles.clickable
              )}
            >
              Rappel
            </div>
          </div>
          <div className={styles.flex}>
            <input
              type="checkbox"
              checked={apt.confirmChecked}
              onChange={props.onToggleConfirm}
              className={styles.checkBox}
              disabled={!props.confirmCheckboxEnabled}
            />
            <div
              onClick={props.onToggleConfirm}
              className={classNames(
                {
                  [styles.formLabel]: true,
                  [styles.disabled]: !props.confirmCheckboxEnabled,
                },
                styles.clickable
              )}
            >
              Demande confirm.
            </div>
          </div>
        </div>

        <div className={styles.buttonGroup}>
          {props.cancelEnabled ? (
            <button
              className={`${styles.button} ${styles.colorDanger}`}
              onClick={props.onCancel}
            >
              <RecycleBin className={styles.buttonIcon} />
              Supprimer
            </button>
          ) : null}
          <OkButton onClick={props.onSave} label="Enregistrer" />
        </div>
      </div>
    </div>
  );
};

export default AptCaracsEdit;
