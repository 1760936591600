import { formaters } from "vincent-utils";
import { Transforms } from "cadendar-shared";
import { css } from "../../../styled-system/css/css";
import { cva } from "../../../styled-system/css/cva";
import Select from "../../baseComps/Select.tsx";
import { Collections } from "cadendar-shared";

interface ChoosePatientDialogProps {
  onChoosePatient: (patientId: string | null) => void;
  onCancel: () => void;
  patientSelectedId: string | null;
  onSetSearch: (search: string) => void;
  search: string;
  candidates: Collections.ExistingPatient[];
}

interface LensIconProps {
  className?: string;
}
const LensIcon = (props: LensIconProps) => (
  <>
    <svg
      className={props.className}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9604 11.4802C19.9604 13.8094 19.0227 15.9176 17.5019 17.4512C16.9332 18.0247 16.2834 18.5173 15.5716 18.9102C14.3594 19.5793 12.9658 19.9604 11.4802 19.9604C6.79672 19.9604 3 16.1637 3 11.4802C3 6.79672 6.79672 3 11.4802 3C16.1637 3 19.9604 6.79672 19.9604 11.4802Z"
        strokeWidth="2"
      />
      <path
        d="M18.1553 18.1553L21.8871 21.8871"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </>
);

// const formLabelTextStyle = cva({
//   base: {
//     fontFamily: "Open Sans",
//     fontSize: 16,
//     fontWeight: 600,
//     cursor: "pointer",
//   },
//   variants: {
//     disabled: {
//       true: {
//         color: "gray.400",
//         cursor: "not-allowed",
//       },
//     },
//   },
// });

const candidateStyle = cva({
  base: {
    fontFamily: "Open Sans",
    fontSize: 16,
    fontWeight: 400,
    cursor: "pointer",
    marginY: 2,
    padding: 1,
  },
  variants: {
    selected: {
      true: {
        backgroundColor: "blue.100",
      },
    },
  },
});

const ChoosePatientDialog = (props: ChoosePatientDialogProps) => {
  const { search, candidates: patients } = props;
  const handleSetSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onSetSearch(event.target.value);
  };
  const sorter = Transforms.patients.curriedSpecialSort(search);
  const sortedCandidates = [...(patients || [])].sort(sorter);

  return (
    <div className={css({ width: "100%", backgroundColor: "gray.50" })}>
      <div
        className={css({
          margin: 2,
          // borderStyle: "solid",
          borderStyle: "none",
          borderWidth: 2,
          borderColor: "gray.200",
          paddingY: 3,
        })}
      >
        <div
          className={css({
            fontSize: 16,
            fontWeight: "bold",
            font: "inherit",
            textAlign: "center",
          })}
        >
          Recherche d'un patient existant
        </div>
        <div
          className={css({
            marginTop: 2,
            display: "flex",
            justifyContent: "center",
          })}
        >
          <div
            className={css({
              position: "relative",
              top: 0,
              left: 0,
              width: "95%",
            })}
          >
            <LensIcon
              className={css({
                width: "1.5em",
                height: "1.5em",
                position: "absolute",
                top: 2,
                right: 2,
                stroke: "black",
              })}
            />
            <input
              type="text"
              value={search}
              id={"patientLookupInput"}
              className={css({
                borderWidth: 2,
                borderColor: "gray.100",
                borderRadius: 4,
                backgroundColor: "white",
                padding: 1,
                width: "100%",
                height: "2.5em",
              })}
              placeholder="Nom du patient"
              onChange={handleSetSearch}
            />
          </div>
        </div>
        <div
          className={css({
            marginTop: 2,
            display: "flex",
            justifyContent: "center",
          })}
        >
          <div
            className={css({
              width: "95%",
              marginTop: 2,
            })}
          >
            {sortedCandidates.length > 0 && (
              <Select
                className={css({ width: "100%" })}
                onChange={(event) =>
                  event && props.onChoosePatient(event.target.value)
                }
                value={props.patientSelectedId || ""}
              >
                {sortedCandidates.map((patient) => (
                  <option
                    key={patient._id}
                    className={candidateStyle({
                      selected: props.patientSelectedId === patient._id,
                    })}
                    value={patient._id}
                  >
                    {formaters.capitaliseEveryFirstLetter(patient.nom)}{" "}
                    {formaters.capitaliseEveryFirstLetter(patient.prenom)}
                  </option>
                ))}
              </Select>
            )}
          </div>
          {/*{sortedCandidates.map((patient) => (*/}
          {/*  <li*/}
          {/*    key={patient._id}*/}
          {/*    onClick={() => props.onChoosePatient(patient._id)}*/}
          {/*    className={candidateStyle({*/}
          {/*      selected: props.patientSelectedId === patient._id,*/}
          {/*    })}*/}
          {/*  >*/}
          {/*    <span*/}
          {/*      className={css({*/}
          {/*        display: "inline-block",*/}
          {/*        fontWeight: 600,*/}
          {/*        _firstLetter: {*/}
          {/*          textTransform: "uppercase",*/}
          {/*        },*/}
          {/*      })}*/}
          {/*    >*/}
          {/*      {patient.nom}*/}
          {/*    </span>*/}
          {/*    <span*/}
          {/*      className={css({*/}
          {/*        display: "inline-block",*/}
          {/*        _firstLetter: {*/}
          {/*          textTransform: "uppercase",*/}
          {/*        },*/}
          {/*      })}*/}
          {/*    >*/}
          {/*      {patient.prenom}*/}
          {/*    </span>*/}
          {/*  </li>*/}
          {/*))}*/}
        </div>
      </div>
    </div>
  );
};

export default ChoosePatientDialog;
