import { css, cva } from "../../styled-system/css";
import { SystemStyleObject } from "../../styled-system/types";
import { styled } from "../../styled-system/jsx";

const buttonCva = cva({
  base: {
    padding: 4,
    fontSize: 16,
    borderRadius: 4,
    cursor: "pointer",
    backgroundColor: "white",
    marginX: 2,
    display: "flex",
  },
  variants: {
    color: {
      danger: {
        color: "white",
        backgroundColor: "red",
      },
      proceed: {
        color: "white",
        backgroundColor: "darkblue",
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        backgroundColor: "gray",
      },
    },
  },
});

interface ButtonProps {
  onClick: () => void;
  type: "danger" | "proceed";
  label: string;
  disabled?: boolean;
  css: SystemStyleObject;
}

const Button = (props: ButtonProps) => {
  console.log("props.disabled", props.disabled);
  return (
    <button
      className={css(buttonCva.raw({ color: props.type }), props.css)}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};

export default Button;

const Button2 = styled("button", buttonCva);

export { Button2 };
