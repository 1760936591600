import styles from "./CancelButton.module.css";

interface CancelButtonProps {
  onClick: () => void;
  label: string;
}

const CancelButton = (props: CancelButtonProps) => {
  return (
    <button
      className={`${styles.button} ${styles.colorCancel}`}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

export default CancelButton;
