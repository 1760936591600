import { css } from "../../styled-system/css";

interface CheckBoxProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  marginLeft?: number;
}

const CheckBox = (props: CheckBoxProps) => {
  const { label, checked, onChange, disabled } = props;
  return (
    <div
      className={css({
        display: "flex",
        marginX: 10,
      })}
    >
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => onChange()}
      />{" "}
      <div
        className={css({
          fontFamily: "Open Sans",
          fontSize: 16,
          fontWeight: 600,
          marginLeft: props.marginLeft || 5,
          cursor: "pointer",
        })}
        onClick={() => onChange()}
      >
        {label}
      </div>
    </div>
  );
};

export default CheckBox;
