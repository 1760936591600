import AgendaMainScreen from "../agenda/AgendaMainScreen.tsx";
import { useState } from "react";
import { Rdv } from "cadendar-shared";

type AptEditSelectedState = {
  screen: "AptEdit";
  selectedApt: Rdv;
};

type AgendaSelectedState = {
  screen: "Agenda";
};

type SelectedState = AptEditSelectedState | AgendaSelectedState;

const ScreenSwitcher = () => {
  const [state] = useState<SelectedState>({ screen: "Agenda" });
  return <div>{state.screen === "Agenda" && <AgendaMainScreen />}</div>;
};

export default ScreenSwitcher;
